import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';
import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/vendors/css/editors/quill/quill.snow.css';
import '../../app/css/plugins/forms/form-quill-editor.css';
import '../../app/vendors/css/jkanban/jkanban.min.css';
import '../../app/css/pages/app-kanban.css';

import flatpickr from "flatpickr";
import select2 from 'select2';
import Quill from 'quill';
import jkanban from 'jkanban';
import Modal from "bootstrap/js/src/modal";
import {swalUnknown} from "../functions";

function renderBoardDropdown_78f9fd8c() {
    let helper = $('#helper');

    return (
        "<div class='dropdown'>" +
        feather.icons['more-vertical'].toSvg({
            class: 'dropdown-toggle cursor-pointer font-medium-3 mr-0',
            id: 'board-dropdown',
            'data-toggle': 'dropdown',
            'aria-haspopup': 'true',
            'aria-expanded': 'false'
        }) +
        "<div class='dropdown-menu dropdown-menu-right' aria-labelledby='board-dropdown'>" +
        "<a class='dropdown-item delete-board' href='javascript:void(0)'> " +
        feather.icons.trash.toSvg({class: 'font-medium-1 align-middle'}) +
        "<span class='align-middle ml-25'>" + helper.data('delete') + "</span></a>" +
        '</div>' +
        '</div>'
    );
}

function renderHeader_78f9fd8c(color, text) {
    return (
        "<div class='d-flex justify-content-between flex-wrap align-items-center mb-1'>" +
        "<div class='item-badges'> " +
        "<div style='color:#ffffff;background-color:" + color + ";' class='badge badge-pill'> " +
        text +
        '</div>' +
        '</div>' +
        '</div>'
    );
}

function updateItems_78f9fd8c() {
    $('.kanban-item').each(function () {
        let thisItem = $(this), text;

        if (thisItem.children('.kanban-text').length > 0) {
            text = "<span class='kanban-text'>" + thisItem.children('.kanban-text').text() + '</span>';
        } else {
            text = "<span class='kanban-text'>" + thisItem.text() + '</span>';
        }

        if (thisItem.attr('data-shop-color') !== undefined && thisItem.attr('data-shop-name') !== undefined) {
            thisItem.html(renderHeader_78f9fd8c(thisItem.attr('data-shop-color'), thisItem.attr('data-shop-name')) + text);
        } else {
            thisItem.html(text);
        }

        if (
            thisItem.attr('data-comments') !== undefined ||
            thisItem.attr('data-due-date') !== undefined ||
            thisItem.attr('data-assignee-name') !== undefined ||
            thisItem.attr('data-assignee-initials') !== undefined
        ) {
            thisItem.append(
                renderFooter_78f9fd8c(
                    thisItem.attr('data-comments'),
                    thisItem.attr('data-due-date'),
                    thisItem.attr('data-assignee-name'),
                    thisItem.attr('data-assignee-initials')
                )
            );
        }
    });
}

function renderFooter_78f9fd8c(comments, dueDate, name, initials) {
    let footer = "<div class='d-flex justify-content-between align-items-center flex-wrap mt-1 activity-bar'><div>";

    if (comments !== undefined) {
        footer += "<span class='align-middle mr-1'>" +
            feather.icons['message-square'].toSvg({class: 'font-medium-1 align-middle mr-25'}) +
            '<span>' + comments + '</span>' + '</span>';
    }

    if (dueDate !== undefined) {
        footer += "<span class='align-middle'>" +
            feather.icons.watch.toSvg({class: 'font-medium-1 align-middle mr-25'}) +
            '<span>' + dueDate + '</span>' + '</span>';
    }

    footer += '</div>';

    if (name !== undefined && initials !== undefined) {
        footer += "<ul class='mb-0'>" +
            "<li class='avatar bg-secondary kanban-item-avatar pull-up' " +
            "data-toggle='tooltip' data-placement='top' " +
            "title='" + name + "'" + '>' +
            "<div class='avatar-content'>" +
            initials +
            '</div>' +
            '</li>' +
            '</ul>';
    }

    return footer + '</div>';
}

function renderLabels_78f9fd8c(option) {
    if (!option.id) {
        return option.text;
    }

    return "<div style='color:#ffffff;background-color:" + $(option.element).data('color') + ";' class='badge badge-pill'> " + option.text + '</div>';
}

function onUpdateItemFormSubmit_78f9fd8c(e) {
    e.preventDefault();

    let helper = $('#helper');
    let sidebar = new Modal(document.getElementById('update-item-sidebar'));
    let form = $('.update-item-form');
    let fd = new FormData(form[0]);
    fd.append('comment', $('.ql-editor').html());

    $.ajax({
        url: helper.data('edit-task'),
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).fail(function (e) {
        swalUnknown(e);
    });

    let item = $('.kanban-item[data-eid="' + $('#task_id').val() + '"]');
    let dueDate = $('#due-date').val();
    let assigneeId = $('#assignee').val();
    let assigneeName = $('#assignee option:selected').text();
    let shopId = $('#label').val();
    let shop = $('#label option:selected');
    let shopName = shop.text();
    let shopColor = shop.data('color');

    if (dueDate.length > 0) {
        item.attr('data-due-date', dueDate);
    }

    if (shopId !== null) {
        item.attr('data-shop-id', shopId);
        item.attr('data-shop-name', shopName);
        item.attr('data-shop-color', shopColor);
    }

    if (assigneeId !== null) {
        item.attr('data-assignee-id', assigneeId);
        item.attr('data-assignee-name', assigneeName);

        let parts = assigneeName.split(' ');
        item.attr('data-assignee-initials', parts[0][0] + parts[1][0]);
    }

    item.children('.kanban-text').text($('#title').val());

    updateItems_78f9fd8c();
    sidebar.hide();
}

function onEditBoardTitle__78f9fd8c() {
    let helper = $('#helper');
    let id = $(this).closest('.kanban-board').data('id');
    let value = $(this).text();

    $.ajax({
        url: helper.data('edit-board'),
        type: "post",
        data: "i=" + id + '&t=' + value,
        async: true
    });
}

function onShownSidebarModal_78f9fd8c() {
    let helper = $('#helper');
    let sidebar = $('#update-item-sidebar');

    $.ajax({
        type: 'POST',
        async: true,
        data: 't=' + $('#task_id').val(),
        url: helper.data('kanban-activity'),
        success: function (data) {
            sidebar.find('.tab-pane-activity').html(data.data);
        }
    });
}

function onHideSidebarModal_78f9fd8c() {
    let sidebar = $('#update-item-sidebar');
    sidebar.find('.ql-editor').html('');
    sidebar.find('.nav-link-activity').removeClass('active');
    sidebar.find('.tab-pane-activity').removeClass('show active');
    sidebar.find('.nav-link-update').addClass('active');
    sidebar.find('.tab-pane-update').addClass('show active');
    sidebar.find('.tab-pane-activity').children('.media').remove();
}

function onBoardTitleHover_78f9fd8c() {
    $(this).attr('contenteditable', 'true');
}

function onNewBoardButton_78f9fd8c() {
    $('.add-new-board-input').toggle();
}

export function initKanbanPage() {
    window.flatpickr = flatpickr;
    window.select2 = select2;
    window.Quill = Quill;
    window.jkanban = jkanban;

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    let helper = $('#helper'),
        boards = [],
        prev = 0,
        openSidebar = true,
        kanbanWrapper = $('.kanban-wrapper'),
        sidebar = $('#update-item-sidebar'),
        sidebarModal = new Modal(document.getElementById('update-item-sidebar')),
        datePicker = $('#due-date'),
        selectors = $('.select2'),
        label = $('#label'),
        assignee = $('#assignee'),
        commentEditor = $('.comment-editor'),
        addNewForm = $('.add-new-board'),
        addNewInput = $('.add-new-board-input');

    $.ajax({
        type: 'POST',
        dataType: 'json',
        async: false,
        url: helper.data('kanban'),
        success: function (data) {
            if (data.length === undefined) {
                boards = [];
            } else {
                boards = data;
            }
        }
    });

    addNewInput.toggle();

    if (datePicker.length) {
        datePicker = datePicker.flatpickr({
            dateFormat: "Y.m.d.",
            allowInput: true
        });
    }

    if (selectors.length) {
        selectors.each(function () {
            let thisSelector = $(this);

            if (thisSelector.data('has-pill')) {
                thisSelector.wrap("<div class='position-relative'></div>").select2({
                    placeholder: helper.data('select-option'),
                    dropdownParent: thisSelector.parent(),
                    templateResult: renderLabels_78f9fd8c,
                    templateSelection: renderLabels_78f9fd8c,
                    escapeMarkup: function (es) {
                        return es;
                    }
                });
            } else {
                thisSelector.wrap("<div class='position-relative'></div>").select2({
                    placeholder: helper.data('select-option'),
                    dropdownParent: thisSelector.parent(),
                    escapeMarkup: function (es) {
                        return es;
                    }
                });
            }
        });
    }

    if (commentEditor.length) {
        new Quill('.comment-editor', {
            modules: {
                toolbar: '.comment-toolbar'
            },
            placeholder: helper.data('comment'),
            theme: 'snow'
        });
    }

    let kanban = new jKanban({
        element: '.kanban-wrapper',
        gutter: '15px',
        widthBoard: '250px',
        dragItems: true,
        boards: boards,
        dragBoards: true,
        addItemButton: true,
        itemAddOptions: {
            enabled: true,
            content: '+ ' + helper.data('add-new'),
            class: 'kanban-title-button btn btn-default btn-xs',
            footer: false
        },
        click: function (el) {
            let element = $(el);
            let titleText;

            if (element.data('eid')) {
                titleText = element.find('.kanban-text').text();
            } else {
                titleText = element.text();
            }

            let title = titleText,
                date = element.attr('data-due-date'),
                dateObj = new Date(),
                labelId = element.attr('data-shop-id'),
                assigneeId = element.attr('data-assignee-id');

            if (element.find('.kanban-item-avatar').length) {
                element.find('.kanban-item-avatar').on('click', function (e) {
                    e.stopPropagation();
                });
            }

            if (!$('.dropdown').hasClass('show') && openSidebar) {
                sidebarModal.show();
            }

            sidebar.find('.update-item-form').on('submit', function (e) {
                e.preventDefault();
            });

            dateObj.getDate();
            datePicker.setDate(date);
            sidebar.find('#title').val(title);
            sidebar.find('#task_id').val(element.attr('data-eid'));
            sidebar.find(label).val(labelId).trigger('change');
            sidebar.find(assignee).val(assigneeId).trigger('change');
            sidebar.find('.delete-task').data('eid', element.attr('data-eid'));
        },
        buttonClick: function (el, boardId) {
            let addNew = document.createElement('form');
            addNew.setAttribute('class', 'new-item-form');
            $(addNew).html('<div class="form-group mb-1">' +
                '<textarea class="form-control add-new-item" rows="2" placeholder="' + helper.data('task-title') + '" required></textarea>' +
                '</div>' +
                '<div class="form-group mb-2">' +
                '<button type="submit" class="btn btn-primary btn-sm mr-1">' + helper.data('save') + '</button>' +
                '<button type="button" class="btn btn-outline-secondary btn-sm cancel-add-item">' + helper.data('cancel') + '</button>' +
                '</div>');
            kanban.addForm(boardId, addNew);
            addNew.querySelector('textarea').focus();
            addNew.addEventListener('submit', function (e) {
                e.preventDefault();
                let currentBoard = $(".kanban-board[data-id='" + boardId + "']");
                let id = boardId + '-' + currentBoard.find('.kanban-item').length + 1;
                kanban.addElement(boardId, {
                    title: "<span class='kanban-text'>" + e.target[0].value + '</span>',
                    id: id
                });

                let item = $('.kanban-item[data-eid="' + id + '"]');
                currentBoard.find('.kanban-item:last-child .kanban-text');
                addNew.remove();

                $.ajax({
                    url: helper.data('new-task'),
                    type: "post",
                    data: "i=" + id + "&o=" + item.prevAll().length + "&t=" + e.target[0].value + "&p=" + boardId,
                    async: true
                });
            });

            $(document).on('click', '.cancel-add-item', function () {
                $(this).closest(addNew).toggle();
            });
        },
        dragEl: function (el) {
            prev = $(el).prevAll().length;
            $(el).find('.item-dropdown, .item-dropdown .dropdown-menu.show').removeClass('show');
        },
        dropEl: function (el) {
            let boardId = $(el).closest('.kanban-board').data('id');
            let id = $(el).data('eid');

            $.ajax({
                url: helper.data('task-parent'),
                type: "post",
                data: "i=" + id + "&b=" + prev + "&p=" + boardId + "&o=" + $(el).prevAll().length,
                async: true
            });
        },
        dragBoard: function (el) {
            prev = $(el).prevAll().length;
        },
        dragendBoard: function (el) {
            $.ajax({
                url: helper.data('board-order'),
                type: "post",
                data: "i=" + $(el).data('id') + "&b=" + prev + "&o=" + $(el).prevAll().length,
                async: true
            });
        }
    });

    if (kanbanWrapper.length) {
        new PerfectScrollbar(kanbanWrapper[0]);
    }

    $('.kanban-container').append(addNewForm);

    $.each($('.kanban-title-button'), function () {
        $(this).removeClass().addClass('kanban-title-button btn btn-flat-secondary btn-sm ml-50');
        Waves.init();
        Waves.attach("[class*='btn-flat-']");
    });

    $.each($('.kanban-board-header'), function () {
        $(this).append(renderBoardDropdown_78f9fd8c());
    });

    updateItems_78f9fd8c();

    $(document).on('click', '.delete-board', function () {
        let id = $(this).closest('.kanban-board').data('id');
        kanban.removeBoard(id);

        $.ajax({
            url: helper.data('delete-board'),
            type: "post",
            data: "i=" + id,
            async: true
        });
    });

    $(document).on('click', '.delete-task', function () {
        let id = $(this).data('eid');
        kanban.removeElement(id);

        $.ajax({
            url: helper.data('delete-task'),
            type: "post",
            data: "i=" + id,
            async: true
        });
    });

    addNewForm.on('submit', function (e) {
        e.preventDefault();
        let thisForm = $(this), value = thisForm.find('.form-control').val(),
            id = value.replace(/\s+/g, '-').toLowerCase();
        kanban.addBoards([{
            id: id,
            title: value
        }]);

        let item = $('.kanban-board[data-id="' + id + '"]').prevAll().length - 1;

        $.ajax({
            url: helper.data('new-board'),
            type: "post",
            data: "i=" + id + "&o=" + item + "&t=" + value,
            async: true
        });

        $('.kanban-board:last-child').find('.kanban-board-header').append(renderBoardDropdown_78f9fd8c());

        addNewInput.val('').css('display', 'none');
        $('.kanban-container').append(addNewForm);

        $.each($('.kanban-title-button'), function () {
            $(this).removeClass().addClass('kanban-title-button btn btn-flat-secondary btn-sm ml-50');
            Waves.init();
            Waves.attach("[class*='btn-flat-']");
        });
    });

    sidebar.on('hidden.bs.modal', onHideSidebarModal_78f9fd8c);
    sidebar.on('shown.bs.modal', onShownSidebarModal_78f9fd8c);

    $('.update-item-form').on('submit', onUpdateItemFormSubmit_78f9fd8c);
    $('.add-new-btn, .cancel-add-new').on('click', onNewBoardButton_78f9fd8c);
    $(document).on('input', '.kanban-title-board', onEditBoardTitle__78f9fd8c);
    $(document).on('mouseenter', '.kanban-title-board', onBoardTitleHover_78f9fd8c);
}

export function unloadKanbanPage() {
    delete window.flatpickr;
    delete window.select2;
    delete window.Quill;
    delete window.jkanban;

    $('.flatpickr-calendar').remove();
    let sidebar = $('#update-item-sidebar');

    sidebar.off('shown.bs.modal', onShownSidebarModal_78f9fd8c);
    sidebar.off('hidden.bs.modal', onHideSidebarModal_78f9fd8c);

    $('.update-item-form').off('submit', onUpdateItemFormSubmit_78f9fd8c);
    $('.add-new-btn, .cancel-add-new').off('click', onNewBoardButton_78f9fd8c);

    $(document).off('input', '.kanban-title-board', onEditBoardTitle__78f9fd8c);
    $(document).off('mouseenter', '.kanban-title-board', onBoardTitleHover_78f9fd8c);
}
